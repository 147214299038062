import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import Input from '../components/Input';
import Button from '../components/Button';
import Text from '../components/Text';
import backgroundImage from '../images/login.jpg';
import Alert from '../components/Alerta';  // Importa el componente de alerta

function BorradoRecibos() {
    const [files, setFiles] = useState([]);
    const [selectedCarga, setSelectedCarga] = useState(null);
    const [cargas, setCargas] = useState([]);
    const [cedula, setCedula] = useState('');
    const [fecha, setFecha] = useState('');
    const [searchedRecibo, setSearchedRecibo] = useState(null);
    const [message, setMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [alertPosition, setAlertPosition] = useState('');

    useEffect(() => {
        fetchUltimasCargas();
    }, []);

    const fetchUltimasCargas = async () => {
        try {
            const response = await axios.get('https://mirecibodesueldo.uy/api/cargas');
            setCargas(response.data);
        } catch (error) {
            console.error('Error fetching cargas:', error);
        }
    };

    const handleSelectCarga = async (carga) => {
        setSelectedCarga(carga);
        try {
            const response = await axios.get(`https://mirecibodesueldo.uy/api/carga/${carga.id}/archivos`);
            const filePromises = response.data.map(async (archivo) => {
                const fileResponse = await axios.get(`https://mirecibodesueldo.uy/api/descargar/${carga.id}/${archivo.nombre}`, {
                    responseType: 'blob'
                });
                const file = new File([fileResponse.data], archivo.nombre, { type: fileResponse.data.type });
                const url = `https://mirecibodesueldo.uy/api/descargar/${carga.id}/${archivo.nombre}`;
                return { file, url };
            });

            const fileObjects = await Promise.all(filePromises);
            setFiles(fileObjects);
        } catch (error) {
            console.error('Error fetching archivos for carga:', error);
        }
    };

    const handleRemoveFile = (index) => {
        setFiles(files.filter((_, i) => i !== index));
    };

    const handleDeleteCargadosRecibos = async () => {
        if (files.length === 0) {
            setMessage('No hay recibos seleccionados');
            setAlertType('Atencion');
            setAlertPosition('left');
            return;
        }
        const fileNames = files.map(file => file.file.name); // Extrae solo los nombres de los archivos
        try {
            await axios.post('https://mirecibodesueldo.uy/api/delete-recibos', { fileNames });
            setMessage('Recibos borrados con éxito');
            setAlertType('Completado');
            setAlertPosition('left');
            setFiles([]);
        } catch (error) {
            setMessage('Error al borrar los recibos');
            setAlertType('Error');
            setAlertPosition('left');
            console.error('Error deleting recibos:', error);
        }
    };

    const handleSearchRecibo = async () => {
        if (!cedula || !fecha) {
            setMessage('Falta llenar algún campo');
            setAlertType('Atencion');
            setAlertPosition('right');
            return;
        }
        try {
            const response = await axios.get(`https://mirecibodesueldo.uy/api/recibos/${cedula}/${fecha}`);
            setSearchedRecibo(response.data);
            setMessage('Se ha encontrado un recibo con esos datos');
            setAlertType('Completado');
            setAlertPosition('right');
        } catch (error) {
            setMessage('Error al buscar el recibo');
            setAlertType('Error');
            setAlertPosition('right');
            console.error('Error fetching recibo:', error);
        }
    };

    const handleDeleteSearchedRecibo = async () => {
        if (!searchedRecibo) {
            setMessage('No hay recibos seleccionados');
            setAlertType('Atencion');
            setAlertPosition('right');
            return;
        }
        try {
            await axios.delete(`https://mirecibodesueldo.uy/api/delete-recibo/${searchedRecibo.id}`);
            setMessage('Recibo borrado con éxito');
            setAlertType('Completado');
            setAlertPosition('right');
            setSearchedRecibo(null);
        } catch (error) {
            setMessage('Error al borrar el recibo');
            setAlertType('Error');
            setAlertPosition('right');
            console.error('Error deleting recibo:', error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        // Ajustar la fecha al día correcto
        date.setDate(date.getDate() + 1);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };

    return (
        <div
            className="flex flex-col items-center justify-center min-h-screen bg-cover bg-center p-4"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="mb-10 bg-gray-200 p-3 rounded-lg shadow-lg w-full max-w-3xl text-center">
                <Text size="3xl" weight="bold" width="fill" className="mb-1 mt-3 text-center text-turquoise-500">Borrado de Recibos</Text>
            </div>
            <div className="flex flex-col md:flex-row w-full max-w-6xl">
                <div className="mt-4 md:mt-0 bg-white p-8 rounded-lg shadow-lg w-full md:w-1/2 md:ml-2">
                    <Text size="2xl" width="fill" className="mb-4 text-center text-black">Últimas Cargas</Text>
                    {message && alertPosition === 'left' && (
                        <div className="mt-4">
                            <Alert message={message} type={alertType} />
                        </div>
                    )}
                    <table className="table-auto w-full text-center">
                        <thead>
                            <tr>
                                <th className="px-4 py-2">Fecha</th>
                                <th className="px-4 py-2">Rol Administrador</th>
                                <th className="px-4 py-2">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cargas.map((carga, index) => (
                                <tr key={index}>
                                    <td className="border px-4 py-2">{formatDate(carga.fecha)}</td>
                                    <td className="border px-4 py-2">{carga.rolAdministrador}</td>
                                    <td className="border px-4 py-2">
                                        <Button type="outline" onClick={() => handleSelectCarga(carga)}>
                                            Ver Archivos
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {selectedCarga && (
                        <div className="mt-4 p-4 bg-gray-100 rounded max-h-76 overflow-y-auto">
                            <Text size="xl" className="text-black text-center mb-3">Archivos seleccionados:</Text>
                            <ul>
                                {files.map(({ file, url }, index) => (
                                    <li key={index} className="flex items-center justify-between">
                                        <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                                            {file.name}
                                        </a>
                                        <button
                                            onClick={() => handleRemoveFile(index)}
                                            className="text-red-500 hover:text-red-700"
                                        >
                                            &times;
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <div className="flex justify-center mt-4">
                                <Button type="secondary" onClick={handleDeleteCargadosRecibos}>
                                    Borrar recibos
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="bg-white p-8 rounded-lg shadow-lg w-full md:w-1/2 md:ml-2">
                    <Text size="2xl" width="fill" className="mb-4 text-center text-black">Buscar Recibo</Text>
                    {message && alertPosition === 'right' && (
                        <div className="mt-4">
                            <Alert message={message} type={alertType} />
                        </div>
                    )}
                    <div className="flex flex-col gap-4">
                        <div>
                            <Text size="lg" className="mb-2">Cédula:</Text>
                            <Input value={cedula} onChange={(e) => setCedula(e.target.value)} className="w-full p-2 border rounded" placeholder="Sin puntos ni guión"/>
                        </div>
                        <div>
                            <Text size="lg" className="mb-2">Fecha (AAAA-MM):</Text>
                            <Input value={fecha} onChange={(e) => setFecha(e.target.value)} className="w-full p-2 border rounded" placeholder="Formato AAAA-MM (Ej: 2023-01)"/>
                        </div>
                        <div className="flex justify-center mt-4">
                            <Button type="secondary" onClick={handleSearchRecibo}>
                                Buscar recibo
                            </Button>
                        </div>
                    </div>
                    {searchedRecibo && (
                        <div className="mt-4 p-4 bg-gray-100 rounded max-h-76 overflow-y-auto">
                            <Text size="xl" className="text-black text-center mb-3">Recibo encontrado:</Text>
                            <ul>
                                <li className="flex items-center justify-between">
                                    <a href={`https://mirecibodesueldo.uy/api/descargarRecibo/${searchedRecibo.id}`} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                                        {searchedRecibo.linkRecibo}
                                    </a>
                                </li>
                            </ul>
                            <div className="flex justify-center mt-4">
                                <Button type="secondary" onClick={handleDeleteSearchedRecibo}>
                                    Borrar recibo
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default BorradoRecibos;



