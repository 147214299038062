import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import Input from '../components/Input';
import Button from '../components/Button';
import backgroundImage from '../images/login.jpg';
import Text from '../components/Text';
import Alert from '../components/Alerta';  // Importa el componente de alerta
import { useNavigate } from 'react-router-dom';  // Importa useNavigate

function Admin3() {
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState('');
  const [alertType, setAlertType] = useState('');  // Estado para el tipo de alerta
  const [subidas, setSubidas] = useState([]);
  const [selectedSubida, setSelectedSubida] = useState(null);
  const [empleadosVencimiento, setEmpleadosVencimiento] = useState([]);
  const navigate = useNavigate();  // Inicializa useNavigate

  useEffect(() => {
    fetchUltimasSubidas();
    fetchEmpleadosVencimiento();
  }, []);

  const fetchUltimasSubidas = async () => {
    try {
      const response = await axios.get('https://mirecibodesueldo.uy/api/subidas');
      console.log('Subidas:', response.data);
      if (Array.isArray(response.data)) {
        setSubidas(response.data);
      } else {
        console.error('Expected an array but got:', response.data);
      }
    } catch (error) {
      console.error('Error fetching subidas:', error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const fileObjects = selectedFiles.map(file => ({
      file,
      url: URL.createObjectURL(file)
    }));
    setFiles(fileObjects);
    setMessage(''); // Limpiar el mensaje cuando se seleccionan nuevos archivos
    setAlertType('');
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (files.length === 0) {
      setMessage('No se ha seleccionado ningún archivo');
      setAlertType('Atencion');
      return;
    }

    const formData = new FormData();
    files.forEach(fileObj => {
      formData.append('files', fileObj.file);
    });

    // Obtener el rol desde la URL
    const rolAdministrador = window.location.pathname.split('/')[1];
    formData.append('rolAdministrador', rolAdministrador);

    try {
      const response = await axios.post('https://mirecibodesueldo.uy/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('Los archivos seleccionados se han subido con éxito');
      setAlertType('Completado');  // Tipo de alerta éxito
      setFiles([]); // Limpiar los archivos seleccionados
      fetchUltimasSubidas(); // Actualizar la lista de subidas después de subir los archivos
    } catch (error) {
      setMessage('Ha ocurrido un error en la subida de los archivos');
      setAlertType('Error');  // Tipo de alerta error
    }
  };

  const handleSelectSubida = async (subida) => {
    setSelectedSubida(subida);
    try {
      const response = await axios.get(`https://mirecibodesueldo.uy/api/subida/${subida.id}/archivos`);
      console.log("PASO EL PRIMER AXIOS")
      const filePromises = response.data.map(async (archivo) => {
        const fileResponse = await axios.get(`https://mirecibodesueldo.uy/api/descargar/${subida.id}/${archivo.nombre}`, {
          responseType: 'blob'
        });
        console.log("PASO EL SEGUNDO AXIOS")
        const file = new File([fileResponse.data], archivo.nombre, { type: fileResponse.data.type });
        const url = `https://mirecibodesueldo.uy/api/descargar/${subida.id}/${archivo.nombre}`;
        return { file, url };
      });
      console.log("PASO EL TERCER AXIOS")
      const fileObjects = await Promise.all(filePromises);
      setFiles(fileObjects);
    } catch (error) {
      console.error('Error fetching archivos for subida:', error);
    }
  };

  const handleRemoveFile = (index, e) => {
    e.preventDefault(); // Evitar el envío del formulario
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleLoadRecibos = async (e) => {
    e.preventDefault();

    if (files.length === 0) {
      setMessage('No se ha seleccionado ningún archivo');
      setAlertType('Atencion');
      return;
    }

    const formData = new FormData();
    files.forEach(fileObj => {
      formData.append('files', fileObj.file);
    });

    // Obtener el rol desde la URL
    const rolAdministrador = window.location.pathname.split('/')[1];
    formData.append('rolAdministrador', rolAdministrador);

    try {
      const response = await axios.post('https://mirecibodesueldo.uy/api/cargarRecibos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('Recibos cargados con éxito');
      setAlertType('Completado');  // Tipo de alerta éxito
      setFiles([]); // Limpiar los archivos seleccionados
    } catch (error) {
      setMessage('Error al cargar los recibos');
      setAlertType('Error');  // Tipo de alerta error
    }
  };

  const fetchEmpleadosVencimiento = async () => {
    try {
      const response = await axios.get('https://mirecibodesueldo.uy/api/empleados/vencimiento-carnet');
      console.log("Empleados con Carnet: ", response.data);
      if (Array.isArray(response.data)) {
        setEmpleadosVencimiento(response.data);
      } else {
        console.error('Expected an array but got:', response.data);
      }
    } catch (error) {
      console.error('Error fetching empleados vencimiento carnet:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Ajustar la fecha al día correcto
    date.setDate(date.getDate() + 1);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen bg-cover bg-center p-4"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="mb-5 bg-gray-200 p-3 rounded-lg shadow-lg w-full max-w-3xl text-center">
        <Text size="3xl" weight="bold" width="fill" className="mb-1 mt-3 text-center text-turquoise-500">Subir Comunicados PDF</Text>
      </div>
      <div className="flex flex-col md:flex-row w-full max-w-6xl">
        <div className="bg-white p-8 rounded-lg shadow-lg w-full md:w-1/2 md:mr-2">
          <form onSubmit={handleUpload} className="flex flex-col gap-4">
            <div className="relative flex justify-center">
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                className="absolute inset-0 opacity-0 cursor-pointer"
                style={{ width: '100%', height: '100%' }}
              />
              <Button type="outline" className="w-56 h-12">
                Elegir comunicados
              </Button>
            </div>
            {files.length > 0 && (
              <div className="mt-4 p-4 bg-gray-100 rounded max-h-76 overflow-y-auto">
                <Text size="xl" className="text-black text-center mb-3">Comunicados seleccionados:</Text>
                <ul>
                  {files.map(({ file, url }, index) => (
                    <li key={index} className="flex items-center justify-between">
                      <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 hover:underline">
                        {file.name}
                      </a>
                      <button
                        onClick={(e) => handleRemoveFile(index, e)}
                        className="text-red-500 hover:text-red-700"
                      >
                        &times;
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="flex justify-center">
              <Button type="secondary" className="w-56 mt-4">
                Subir comunicados
              </Button>
            </div>
            <div className="flex justify-center">
              <Button type="secondary" onClick={handleLoadRecibos} className="w-56 mt-4">
                Cargar comunicados a empleados
              </Button>
            </div>
            <div className="flex justify-center">
              <Button type="outline" className="w-56 mt-4 underline" onClick={() => navigate('/borrado-recibos')}>
                Borrado de recibos
              </Button>
            </div>
          </form>
          {message && (
            <div className="mt-4">
              <Alert message={message} type={alertType} />
            </div>
          )}
        </div>
        <div className="mt-4 md:mt-0 bg-white p-8 rounded-lg shadow-lg w-full md:w-1/2 md:ml-2">
          <Text size="2xl" width="fill" className="mb-4 text-center text-black">Últimas Subidas</Text>
          <table className="table-auto w-full text-center">
            <thead>
              <tr>
                <th className="px-4 py-2">Fecha</th>
                <th className="px-4 py-2">Rol Administrador</th>
                <th className="px-4 py-2">Acción</th>
              </tr>
            </thead>
            <tbody>
              {subidas.map((subida, index) => (
                <tr key={index}>
                  <td className="border px-4 py-2">{formatDate(subida.fecha)}</td>
                  <td className="border px-4 py-2">{subida.rolAdministrador}</td>
                  <td className="border px-4 py-2">
                    <Button type="outline" onClick={() => handleSelectSubida(subida)}>
                      Ver Comunicados
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mt-10 bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl">
        <Text size="2xl" width="fill" className="mb-4 text-center text-black">Carnets Próximos a Vencer</Text>
        <table className="table-auto w-full text-center">
          <thead>
            <tr>
              <th className="px-4 py-2">Nombre Empleado</th>
              <th className="px-4 py-2">Cédula</th>
              <th className="px-4 py-2">Vencimiento Carnet de Salud</th>
            </tr>
          </thead>
          <tbody>
            {empleadosVencimiento.map((empleado, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{empleado.nombre}</td>
                <td className="border px-4 py-2">{empleado.cedula}</td>
                <td className="border px-4 py-2">{formatDate(empleado.vencimientoCarnetDeSalud)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Admin3;


